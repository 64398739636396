import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {useAdsContext} from './AdsProvider'
import {dfp} from './dfp'

/**
 * Render the Ad component.
 * @param  {object}        props           The component attributes as props.
 * @param  {string}        props.className Optional class names.
 * @param  {number|string} props.height    The ad height.
 * @param  {string}        props.id        The ad item ID.
 * @param  {object}        props.style     Custom styles.
 * @param  {number|string} props.width     The ad width.
 * @return {Element}                       The Ad component.
 */
export default function Ad({
  className,
  height = 250,
  id,
  style = {},
  width = 350
}) {
  const {isLoading} = useAdsContext()

  useEffect(() => {
    if (!isLoading && !!id) {
      dfp.showSlot(id)
    }
  }, [isLoading, id])

  return (
    <div
      id={id}
      className={className}
      style={{
        ...style,
        width,
        height
      }}
    />
  )
}

Ad.propTypes = {
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}
