import Ad from '@/components/organisms/Ad'
import cn from 'classnames'
import {useSession} from 'next-auth/react'
import PropTypes from 'prop-types'
import styles from './BlockAdSlot.module.css'

/**
 * Render the Ad Slot Block Component.
 * @param  {object}  props           Component Props.
 * @param  {string}  props.align     Optional alignment style.
 * @param  {string}  props.className Optional className.
 * @param  {string}  props.blockId   Randomly generated block ID.
 * @return {Element}                 The Ad Slot Block Component.
 * @see https://github.com/BlackBoxVision/next-google-dfp
 */
export default function BlockAdSlot({align, blockId, className}) {
  const {status} = useSession()

  if (!blockId || status === 'loading') {
    return null
  }

  const adBlockClassNames = cn(
    styles.adSlot,
    className,
    align === 'center' && styles.alignCenter,
    align === 'right' && styles.alignRight
  )

  return (
    <div className={adBlockClassNames}>
      <Ad id={`gpt-ad-${blockId}`} width="auto" height="auto" />
    </div>
  )
}

BlockAdSlot.propTypes = {
  align: PropTypes.string,
  blockId: PropTypes.string,
  className: PropTypes.string
}
